import React, { useState, useEffect } from "react";
import "./App.css";

// Ajoutez cette fonction en haut du fichier, en dehors du composant
function getCurrentDate(): string {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Janvier est 0!
  const year = today.getFullYear();

  return `${day}/${month}/${year}`;
}

const App: React.FC = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setMousePosition({
        x: event.clientX / window.innerWidth,
        y: event.clientY / window.innerHeight,
      });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const filter = document.querySelector("#pagePixelate feGaussianBlur");
    if (filter) {
      const blurAmount = mousePosition.x * 5; // Ajustez selon vos préférences
      filter.setAttribute("stdDeviation", blurAmount.toString());
    }

    const colorMatrix = document.querySelector("#pagePixelate feColorMatrix");
    if (colorMatrix) {
      const matrixValue = 19 - Math.floor(mousePosition.y * 18); // Ajustez selon vos préférences
      const values = `1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 ${matrixValue} -9`;
      colorMatrix.setAttribute("values", values);
    }
  }, [mousePosition]);

  // Calculer les valeurs en fonction de la position de la souris
  const pixelSize = Math.max(2, Math.floor(mousePosition.x * 20)); // Entre 2 et 20 pixels
  const blurAmount = mousePosition.y * 2; // Entre 0 et 2
  const displacementScale = Math.max(10, Math.floor(mousePosition.x * 100)); // Entre 10 et 100

  return (
    <div className="App">
      <div className="overlay grain-effect"></div>
      <div className="overlay scanlines-effect"></div>
      <div className="overlay color-shift-effect"></div>
      <header>
        <svg
          width="240"
          height="240"
          viewBox="0 0 240 240"
          preserveAspectRatio="xMidYMid meet"
        >
          <defs>
            <filter id="pixelate" x="0%" y="0%" width="100%" height="100%">
              <feGaussianBlur
                stdDeviation={blurAmount}
                in="SourceGraphic"
                result="smoothed"
              />
              <feImage
                width={pixelSize}
                height={pixelSize}
                href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAIAAAACDbGyAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAWSURBVAgdY1ywgOEDAwKxgJhIgFQ+AP/vCNK2s+8LAAAAAElFTkSuQmCC"
                result="displacement-map"
              />
              <feTile in="displacement-map" result="pixelate-map" />
              <feDisplacementMap
                in="smoothed"
                in2="pixelate-map"
                xChannelSelector="R"
                yChannelSelector="G"
                scale={displacementScale}
                result="pre-final"
              />
              <feComposite operator="in" in2="SourceGraphic" />
            </filter>
          </defs>
          <image
            filter="url(#pixelate)"
            width="240"
            height="240"
            preserveAspectRatio="xMidYMid meet"
            href="/images/logo.svg"
          />
        </svg>
        <h1>
          Bienvenue sur le site Internets™ de <br /> la Compagnie des Internets™
          Bordelaise.{" "}
        </h1>
      </header>
      <main>
        <section>
          {" "}
          <p>
            {" "}
            Bienvenue chez{" "}
            <strong>la Compagnie des Internets™️ Bordelaise</strong>, votre
            partenaire de confiance pour tout ce qui concerne le web, les
            applications mobiles WAP et l’infographie dans ce nouveau millénaire
            ! Basée à Bordeaux, notre entreprise se spécialise dans la création
            de sites web sur mesure, à la pointe des tendances technologiques
            d’aujourd’hui et de demain.{" "}
          </p>{" "}
          <p>
            {" "}
            En plus de notre expertise en <em>webmastering</em> et en création
            graphique, nous sommes fiers de proposer des solutions innovantes
            pour le mobile. Avec le développement croissant de l'Internet
            mobile, nous concevons des <strong>applications WAP</strong>{" "}
            (Wireless Application Protocol), adaptées aux téléphones mobiles de
            la nouvelle génération. Grâce à nos services, vos utilisateurs
            peuvent accéder à votre contenu où qu’ils soient, depuis leur
            téléphone portable.{" "}
          </p>{" "}
          <p>
            {" "}
            Nos services d'infographie professionnelle vous permettent également
            de concevoir vos logos, bannières et publicités avec une touche
            unique qui reflète votre identité de marque. Que vous soyez une PME,
            un artisan ou une grande entreprise, nous nous adaptons à vos
            besoins pour vous fournir des solutions personnalisées et efficaces.{" "}
          </p>{" "}
          <p>
            {" "}
            Chez la Compagnie des Internets™️ Bordelaise, nous croyons que
            l’avenir est en ligne et que chaque entreprise mérite une présence
            web forte, à la fois sur les navigateurs traditionnels et les
            téléphones mobiles, pour s’imposer dans ce nouvel espace digital en
            pleine expansion.{" "}
          </p>
        </section>
      </main>
      <footer>
        <div>
          <p>
            <strong>LA COMPAGNIE DES INTERNETS BORDELAISE</strong>
            <br />
            Président :{" "}
            <a href="https://lucchaissac.com" target="_blank">
              Luc Chaissac
            </a>
            <br />
            Unité légale ► <strong>934 021 403</strong>
            <br />
            En activité depuis le 8 octobre 2024
            <br />
            SAS, société par actions simplifiée
            <br />
            Domaine d’activité : Programmation informatique (NAF/APE 62.01Z)
            <br />
            Siège social : <strong>33000 BORDEAUX, FRANCE</strong>
            <br />
            SIREN : <strong>934 021 403</strong>
            <br />
            SIRET du siège social : <strong>934 021 403 00019</strong>
            <br />
            N° TVA Intracommunautaire : <strong>FR01 934 021 403</strong>
            <br />
            <em>Pas de salariés déclarés</em>
            <br />
          </p>

          <p>
            <strong>Sources :</strong> INSEE, VIES, INPI
            <br />
            Immatriculée au RNE (Registre National des Entreprises), mise à jour
            le {getCurrentDate()}
            <br />
          </p>

          <p>
            <a href="https://legifrance.gouv.fr" target="_blank">
              legifrance.gouv.fr
            </a>{" "}
            |
            <a href="https://service-public.fr" target="_blank">
              service-public.fr
            </a>{" "}
            |
            <a href="https://data.gouv.fr" target="_blank">
              data.gouv.fr
            </a>
          </p>

          <p>
            Typographie de titre Mondwest par{" "}
            <a href="https://pangrampangram.com/" target="_blank">
              © Pangram Pangram® Foundry
            </a>{" "}
            — Typographie de corp Departure Mono par{" "}
            <a href="https://www.helenazhang.com/" target="_blank">
              Helena
            </a>
          </p>

          <p>
            <em>
              © 2024 LA COMPAGNIE DES INTERNETS BORDELAISE - Tous droits
              réservés
            </em>
            <br />
            Conformément à la loi française, nous vous informons que ce site ne
            collecte aucune donnée personnelle des utilisateurs.
          </p>
        </div>
      </footer>
      {/* Ajoutez ces lignes à la fin, juste avant la fermeture de la div principale */}
      <div className="cathode-lines">
        <div className="scanlines"></div>
        <div className="color-lines"></div>
      </div>
    </div>
  );
};

export default App;
